<template>
  <div class="home_page">
    <div class="first_block">
      <el-carousel :interval="5000" trigger="click" arrow="never">
        <el-carousel-item v-for="item in lunbo" :key="item.sline">
          <div class="banner_text">
            <div class="big_font">{{item.fline}}</div>
            <div class="big_font">{{item.sline}}</div>
            <div class="banner_line"></div>
            <div>{{item.sub}}</div>
            <el-button type="primary" size="mini" @click="$myutils.jumpto('apply')">咨询详情</el-button>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="second_block">
      <div class="old_s" data-aos="fade-right" @click="$myutils.jumpto('service')">
        <div class="second_tit">
          网络安全专项服务
          <div style="font-size:1vw;">Network Security Special Services</div>
        </div>
        <div class="bref">
          <span v-for="item in old_s" :key="item">{{item}}</span>
        </div>
      </div>
      <div class="new_s" data-aos="fade-left" @click="$myutils.jumpto('comprehensive')">
        <div class="second_tit">
          MNSS安全综合服务平台
          <div style="font-size:1vw;">Multipurpose Network Security Services</div>
        </div>
        <div class="bref">
           <span v-for="item in mnss" :key="item">{{item}}</span>
        </div>
      </div>
    </div>
    <div class="third_block">
      <div class="third_tit">
        <div>行业解决方案</div>
        <div class="line"></div>
        <div>INDUSTRY  SOLUTIONS</div>
      </div>
      <div class="bref">根据企业自身特点与需求，为您打造最优定制化解决方案</div>
      <el-tabs tab-position="left" data-aos="zoom-in">
        <el-tab-pane label="银行机构">
          <div class="all_cnt">
            <div class="tab_tit">银行机构解决方案</div>
            <div>
              银行服务机构一直是网络攻击者最感兴趣的目标。在线应用的漏洞、未经保护的应用程序和日益增长的公有云服务正在迅速扩大攻击面。网络黑客不断攻击这些易受攻击的系统，这些系统是金融企业需要重点保护的对象。
            </div>
            <el-button type="primary" size="mini" @click="$myutils.jumpto('apply')">联系我们</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="证券服务">
          <div class="all_cnt">
            <div class="tab_tit">证券服务解决方案</div>
            <div>
              随着云计算、AI、大数据等信息技术的不断发展，证券行业信息化的步伐不断加快，智能化的水平不断提高，基于此，安全威胁治理水平也需要同步不断提升。为了提升证券行业的数字安全水平，政府和行业从政策上也进行了诸多引导和规定。
            </div>
            <el-button type="primary" size="mini" @click="$myutils.jumpto('apply')">联系我们</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="泛互联网">
          <div class="all_cnt">
            <div class="tab_tit">泛互联网解决方案</div>
            <div>
              泛互联网行业保持高速发展，大部分企业集中力量拓展业务，寻求更多的商机，但在应用保护方面投入人力有限，基本无力对应用实施高强度、完整的安全方案，更难以抵挡多样化的攻击手段。
            </div>
            <el-button type="primary" size="mini" @click="$myutils.jumpto('apply')">联系我们</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="能源电力">
          <div class="all_cnt">
            <div class="tab_tit">能源电力解决方案</div>
            <div>
              电力、石油等能源公司正在使生产发电、电网传输控制、采油流程控制等过程完成数字化，以提高可靠性并降低成本。随着能源企业IT化的加深及自动化程度的提升， 他们面对的潜在网络攻击面也越来越大。确保自动化控制应用软件的安全性，对保障能源企业的运营安全至关重要。
            </div>
            <el-button type="primary" size="mini" @click="$myutils.jumpto('apply')">联系我们</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="智能制造">
          <div class="all_cnt">
            <div class="tab_tit">智能制造解决方案</div>
            <div>
              随着车联网、IoT等行业的不断发展，数字化转型的不断加快，企业面临着更加严峻、更加迫切的应用安全需求，以应对信息安全诉求；特别是随着监管、合规要求的不断发布，都给智能制造提出了新的安全规范，做好安全防御不容有失。
            </div>
            <el-button type="primary" size="mini" @click="$myutils.jumpto('apply')">联系我们</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="通讯运营商">
          <div class="all_cnt">
            <div class="tab_tit">通讯运营商解决方案</div>
            <div>
              通信运营商的数字化转型，加速了各分支机构的连通、联动。然而，信息化技术的不断发展，业务系统广泛应用的同时也带来了安全威胁问题。做好应用安全防护，越来越需要引起通信运营商行业的重视。
            </div>
            <el-button type="primary" size="mini" @click="$myutils.jumpto('apply')">联系我们</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="政府央企">
          <div class="all_cnt">
            <div class="tab_tit">政府央企解决方案</div>
            <div>
              随着云计算的建设推广，电子政务得到了快速发展与应用。然而政务数据共享、数据互通也会带来不可避免的安全挑战，且也越来越多。电子政务系统的安全稳定性关乎着国民经济的健康运行，对于政府各机关单位而言，重要性不言而喻。
            </div>
            <el-button type="primary" size="mini" @click="$myutils.jumpto('apply')">联系我们</el-button>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div class="phone_cnt">
        <span>银行机构</span>
        <span>证券服务</span>
        <span>泛互联网</span>
        <span>能源电力</span>
        <span>智能制造</span>
        <span>通讯运营商</span>
        <span>政府央企</span>
      </div>
    </div>
    <div class="fourth_block">
      <div class="news">
        <div class="n_same n_left">
          <img :src="news_list[0]?.content.news_item[0].thumb_url" alt="">
          <p class="news_tit">{{news_list[0]?.content.news_item[0].title}}</p>
          <span>{{$myutils.swichTime(news_list[0]?.update_time)}} {{news_list[0]?.content.news_item[0].author}}</span>
          <p class="two_line">主要内容：{{news_list[0]?.content.news_item[0].digest}}</p>
          
          <div style="text-align:right; margin-top:20px;">
            <el-button type="primary" @click="toNews(news_list[0].article_id)">了解详情</el-button>
          </div>
        </div>
        <div class="n_same n_right">
          <div class="one_out" v-for="(item,index) in news_list" :key="index">
            <div v-if="index !=0" class="one_news" @click="toNews(item.article_id)">
              <img :src="news_list[index]?.content.news_item[0].thumb_url" alt="">
              <div class="news_text">
                <p class="news_tit">{{news_list[index]?.content.news_item[0].title}}</p>
                  <span>{{$myutils.swichTime(news_list[index]?.update_time)}} {{news_list[0]?.content.news_item[0].author}}</span>
                <p class="two_line">主要内容：{{news_list[index]?.content.news_item[0].digest}}...</p>
              </div>
            </div>
          </div>
          <div style="text-align:right; margin-top:30px;">
            <el-popover
              placement="left"
              title="关注我们"
              trigger="click">
              <img src="../assets/xf.jpg" alt="" width="150">
              <el-button type="primary" slot="reference">了解更多</el-button>
            </el-popover>
            
          </div>
        </div>
      </div>
      <div class="phone_news">
        <div class="one_out" v-for="(item,index) in news_list" :key="index">
          <div class="one_news" @click="toNews(item.article_id)">
            <img :src="news_list[index]?.content.news_item[0].thumb_url" alt="">
            <div class="news_text">
              <p class="news_tit">{{news_list[index]?.content.news_item[0].title}}</p>
                <span>{{$myutils.swichTime(news_list[index]?.update_time)}} {{news_list[0]?.content.news_item[0].author}}</span>
              <p class="two_line">主要内容：{{news_list[index]?.content.news_item[0].digest}}...</p>
            </div>
          </div>
        </div>
        <div style="text-align:right;">
          <el-popover
            placement="left"
            title="关注我们"
            trigger="click">
            <img src="../assets/xf.jpg" alt="" width="150">
            <el-button type="primary" slot="reference">了解更多</el-button>
          </el-popover>
            
          </div>
      </div>
    </div>
    <div class="roll_block">
      <vue-seamless-scroll :data="logos" :class-option="classOption" class="scroll_cnt">
        <ul class="in_block">
          <li v-for="item in logos" :key="item">
            <img :src="getImg(item)" alt="" height="80">
            <div>{{item.slice(1)}}</div>
          </li>
        </ul>
      </vue-seamless-scroll>
    </div>
  </div>
</template>

<script>
// import AOS from 'aos';
import logos from '../utils/imgs.js'
import vueSeamlessScroll from "vue-seamless-scroll";

export default {
  data(){
    return {
      lunbo:[
        {fline:'先于攻击解决风险',sline:'让安全领先一步',sub:'网络安全风险解决服务提供商'},
        {fline:'赛虎科技',sline:'MNSS安全综合服务平台',sub:'专业安全团队7x24提供直观的结果交付型服务'},
        {fline:'赛虎科技',sline:'信息安全专项服务',sub:'量身打造专业的定制安全服务'}
      ],
      news_list:[],
      old_s:['渗透测试','风险评估','安全巡检','应急处理','安全保障','安全培训','攻防演练'],
      mnss:['7X24安全能力','人机共智模式','体系化服务输出','积极主动防御','风险处置闭环','结果导向交付'],
      logos:logos,
      logosW:20,
      except:[],
      // logos_w:
    }
  },
  methods:{
    getImg(item) {
      return require('../assets/home/zs/'+`${item}`+'.png');
    },
    getToken(){
      this.$http.post('/get_token')
      .then( res =>{
        // console.log(res)
        if(res.data.code == 200){
          let temp = res.data.result.slice(0, 4); 
          this.news_list = temp.filter(one =>this.except.indexOf(one.content.news_item[0].author) === -1)
        }
      })
    },
    // getNews(token){
    //   this.$http({
    //     method:'post',
    //     url: 'https://api.weixin.qq.com/cgi-bin/material/batchget_material?access_token='+token,
    //     data: {"offset":0,"count":4,"type":"news"},
    //   }).then(res => {
    //     console.log(res)
    //     // if(res.data.code == 200){
    //     //   this.$message.success('提交成功！我们会尽快与您联系！');
    //     // }else{
    //     //   this.$message.error(res.data.msg)
    //     // } 
    //   }).catch(error=>{
    //     console.log(error);
    //     // this.getvcode();
    //   })
    // },
    toNews(id){
      let routeUrl = this.$router.resolve({
          path: "/onenews",
          query:{theNews:id}
      });
      window.open(routeUrl.href, '_blank');
    },
    getLogowidth(){
      var element = document.querySelector('.scroll_cnt'); 
      var width = window.getComputedStyle(element).width;
      if(parseInt(width.replace('px', ''))<1300) this.logosW = 2
      // console.log(width)
    }
  },
  comments:{
    vueSeamlessScroll
  },
  computed: {
    classOption() {
      return {
        step: 0.1, // 数值越大速度滚动越快
        limitMoveNum: this.logosW, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
      };
    },
  },
  created(){
    // console.log(logos)
    this.getToken()
  },
  mounted(){
    this.$aos.init({
      offset:200,
      duration: 600,
      easing: 'ease-in-sine',
      disable: 'phone'
    });
    this.getLogowidth()
  }
}
</script>

<style lang="scss" scoped>
  .home_page ::v-deep{
    color:#fff;
    .first_block{
      width: 100%;
      height: calc(100vh - 70px);
      background-size:100% 100%;
      background: url('../assets/home/banner.jpg') no-repeat;
      background-size: cover;
      background-position: center;
      .el-carousel{
        // width: 60%;
        height: calc(100vh - 70px);
        
        position: relative;
        .el-carousel__container{
          position: absolute;
          top:26vh;
          left:10vw;
          width: 46vw;
          height: 40vh;
          .el-carousel__item{
            .banner_text{
              font-size: 1.4vw;
              .big_font{
                font-size: 4vw;
                font-weight: 600;
              }
              .banner_line{
                height: 2px;
                width: 40vw;
                background-color: #fff;
                margin:16px 0;
              }
              .el-button{
                margin-top: 16px;
              }
            }
          }
        }
        .el-carousel__indicators--horizontal{
          bottom: 40px;
        }
        .el-carousel__indicator.is-active button{
          background-color: aqua;
        }
      }
    }
    .second_block{
      display: flex;
      padding: 0 8vw;
      justify-content: space-between;
      align-items: center;
      background: url('../assets/home/bg1.jpg') no-repeat;
      background-size: 100% 100%;
      width: 100%;
      height: calc(100vw/2.4);
      font-size: 1vw;
      .second_tit{
        font-size: 2.2vw;
        font-weight: bold;
        margin-bottom: 26px;
      }
      .old_s{
        width: 35%;
        cursor: pointer;
        span{
          display:inline-block;
          white-space:nowrap;
          padding: 5px 8px;
          background-color: $defult;
          margin-right: 10px;
          margin-bottom: 15px;
          border-radius: 5px;
          // color: $dark-base;
        }
      }
      .new_s{
        cursor: pointer;
        width: 40%;
        span{
          display:inline-block;
          white-space:nowrap;
          padding: 5px 8px;
          background-color: $dark-base;
          margin-right: 10px;
          margin-bottom: 15px;
          border-radius: 5px;
          // color: $dark-base;
        }
      }
    }
    .third_block{
      padding: 64px;
      background: url('../assets/home/hyjjfa.png') no-repeat;
      background-size: cover;
      font-size: 1.2vw;
      .third_tit{
        text-align: center;
        div{
          &:first-child{
            font-size: 2.5vw;
            font-weight: bold;
          }
          &:last-child{
            color: $gray-base;
            font-size: 0.9vw;
          }        
        }
        .line{
          width: 20vw;
          height: 1px;
          background-color: #fff;
          margin: 6px auto;
        }
      }
      .bref{
        margin: 30px 0;
        text-align: center;
      }
      .el-tabs{
        font-size: 1.2vw;
        .el-tabs__header{
          .el-tabs__item{
            color: #fff;
            font-size: 1vw;
          }
          .is-active{
            color: $defult;
          }
        }
        .el-tabs__content{
          .el-tab-pane{
            background-color: rgba(17,48,92,0.8);
            padding: 80px 0;
            .all_cnt{
              width: 70%;
              margin: 0 auto;
              .tab_tit{
                font-size: 1.5vw;
                font-weight: bold;
              }
              div{
                &:nth-child(2){
                  margin: 40px 0;
                }
              }
            }
          }
        }
      }
      .phone_cnt{
        display: none;
        // margin-top: 10px;
        span{
          display: inline-block;
          padding: 5px 8px;
          background-color: $defult;
          margin-right: 10px;
          margin-bottom: 15px;
          border-radius: 5px;
        }
      }
    }
    .fourth_block{
      background-color: $dark-base;
      .news,.phone_news{
        display: flex;
        justify-content: space-around;
        background-color: $light-base;
        color: $dark-base;
        padding: 64px;
        .n_same{
          width: 43vw;
          img{
            margin-bottom: 16px;
            border: 2px solid $dark-base;
            width: 100%;
            height: calc(42vw/2.5);
          }
          span{
            display: block;
            color: $gray-base;
            margin: 10px 0;
          }
          .news_tit{
            font-weight: bold;
            white-space: nowrap; /* 防止文本换行 */  
            overflow: hidden; /* 隐藏超出部分的文本 */  
            text-overflow: ellipsis; /* 当文本溢出时显示省略号 */  
          }
          .two_line{
            display: -webkit-box;  
            -webkit-box-orient: vertical;  
            -webkit-line-clamp: 2;  
            overflow: hidden;  
            text-overflow: ellipsis; 
          }
        }
        .n_left{
          padding: 30px;
          background-color: #fff;
        }
        .n_right{
          background-color: #fff;
          padding: 30px;
          .one_out{
            width: 100%;
            .one_news{
              display: flex;
              align-items: center;
              background-color: #fff;
              .news_text{
                width: calc(100% - 10vw - 20px);
              }
            }
            &:nth-child(3){
              margin: 20px 0;
              padding:20px 0;
              border-top: .5px solid $dark-base;
              border-bottom: .5px solid $dark-base;
            }
          }
          
          img{
            width: 10vw;
            height: calc(10vw/1.8);
            margin-bottom: unset;
            margin-right: 20px;

          }
          span{
            margin: 5px 0;
          }
        }
      }
      .phone_news{
        display: none;
        padding: 30px;
        .one_out{
          width: 100%;
          .one_news{
            background-color: #fff;
            margin-bottom: 30px;
            .news_text{
              // width: calc(100% - 10vw - 20px);
              padding: 10px;
              font-size: 14px;
            }
            img{
              margin-bottom: 16px;
              border: 1px solid $dark-base;
              width: calc(100% - 2px);
            }
            span{
              display: block;
              color: $gray-base;
              margin: 10px 0;
            }
            .news_tit{
              font-weight: bold;
              white-space: nowrap; /* 防止文本换行 */  
              overflow: hidden; /* 隐藏超出部分的文本 */  
              text-overflow: ellipsis; /* 当文本溢出时显示省略号 */  
            }
            .two_line{
              display: -webkit-box;  
              -webkit-box-orient: vertical;  
              -webkit-line-clamp: 2;  
              overflow: hidden;  
              text-overflow: ellipsis; 
            }
          }
        }
      }
      .el-button{
        span{
          margin: unset!important;
          color: #fff!important;
        }
      }
    }
    .roll_block{
      background-color: $dark-base;
      .scroll_cnt{
        overflow: hidden;
        // height: 105px;
        width: 90vw;
        max-width: 1300px;
        margin: 0 auto;
        // margin: 0 auto 60px auto;
        ul {
          display: flex;
          li {
            width: 110px;
            margin: 0 10px;
            background-color: $dark-base;
            text-align: center;
            list-style:none;
            font-size: 12px;
          }
          img{
            width: auto;
            height: 80px;
            display: block;
            margin: 0 auto;
            margin-top: 30px;
            margin-bottom: 10px;
          }
        }
      }
    }
    @media screen and (max-width: 1080px) {
      .first_block{
        height: 400px;
        .el-carousel{
          height: 400px;
          .el-carousel__container{
            height: 200px;
            top:100px;
            width: 80%;
            .el-carousel__item{
              .banner_text{
                font-size: 14px;
                .big_font{
                  font-size: 28px;
                }
                .banner_line{
                  height: 2px;
                  width: 100%;
                }
                .el-button{
                  margin-top: 16px;
                }
              }
            }
          }
        }
      }
      .second_block {
        flex-direction: column;
        padding: unset;
        background: unset;
        height: unset;
        font-size: 11px;
        .second_tit{
          font-size: 18px;
          margin-bottom: 16px;
          div{
            &:nth-child(1){
              font-size: 10px!important;
            }
          }
        }
        .old_s,.new_s{
          width: 100%;
          background: #142c4d;
          padding: 30px 40px;
          padding-top: 45px;
          span{
            // margin-bottom: unset;
          }
        }
        .new_s{
          background: #31728d;
        }
      }
      .third_block{
        font-size: 12px;
        padding: 40px;
        .el-tabs{ display: none; }
        .third_tit{
          div{
            &:first-child{
              font-size: 18px;
            }
            &:last-child{
              color: $gray-base;
              font-size: 10px;
            }        
          }
          .line{
            width: 120px;
          }
        }
        .bref{ margin: 20px 0;}
        .phone_cnt{
          display: block;
        }
      }
      .fourth_block{
        background-color: $dark-base;
        .news{display: none; }
        .phone_news{
          display: block;
          
        }
        .el-button{
          span{
            margin: unset!important;
            color: #fff!important;
          }
        }
      }
      .box {
        height: 100px;
      }
    }
  }
</style>
